import { Image } from '/features/buildingBlocks/Image'

import styles from './LargeImage.css'

export function LargeImage({ image }) {

  return (
    <section data-x='large-image' className={styles.component}>
      <Image layoutClassName={styles.imageLayout} {...{ image }} />
    </section>
  )
}
